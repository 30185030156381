.c-header {
    position: relative;
    display: block;
    padding: 0.5rem;
    background-color: $black;
    color: $white;
    text-align: center;
}
.c-header__title {
    display: block;
    margin: 0;
}
.c-header__back {
    position: absolute;
    top: 50%;
    left: 1rem;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    font-size: .875rem;
    text-decoration: none;
    text-transform: lowercase;
    transform: translateY(-50%);
    @include media-breakpoint-down(lg) {
        left: 0.5rem;
        font-size: 1.125rem;
    }
    .c-back__ico {
        width: 1em;
        height: 1em;
        font-size: 1.29em;
    }
    .c-back__label {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}