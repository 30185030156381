.c-engines {
    .c-section + & {
        margin-top: -2.5rem;
    }
}
.c-engine {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.5rem;
    outline: 1px solid $gray-100;
    outline-offset: -1px;
    background-color: $white;
    text-align: center;
    &.active {
        outline-width: 2px;
        outline-offset: -2px;
        outline-color: $primary;
    }
}
.c-engine__head {
    width: 100%;
}
.c-engine__title {
    display: block;
    margin: 1rem 0;
}
.c-engine__body {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}
.c-engine__price {
    display: block;
    margin: 1rem 0 0;
    .c-price {
        color: inherit;
    }
    .c-price__title {
        margin-right: 0.3em;
    }
    .c-price__hint {
        position: static;
        display: inline-block;
        width: 1em;
        margin: 0 0 0 0.6em;
        color: inherit;
        line-height: inherit;
    }
    .c-price__hint-ico {
        vertical-align: baseline;
        width: 100%;
    }
}
.c-engine__descr {
    display: block;
    margin: 0.25rem 0 0;
}
.c-engine__tech {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    .c-tech {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        min-height: 108px;
        padding: 0.25rem;
        background-color: $gray-100;
        color: $gray-700;
        font-size: 0.8125rem;
        text-align: center;
        line-height: 1.4;
    }
    .c-tech__ico {
        width: 1em;
        height: 1em;
        font-size: 1.5rem;
        fill: currentColor;
    }
}
.c-engine__tech-item {
    flex: 1;
    padding: 0.5rem;
}
.c-engine__btnline {
    display: block;
    margin: 1rem 0;
}
.c-engine__footer {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 0.5rem;
    border-top: 1px solid $gray-100;
}
.c-engine__check {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
}