$spinner-bg: rgba(255, 255, 255, 0.4);

.spinner-wrap {
  position: fixed;
  display: grid;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $spinner-bg;
  place-items: center;
  z-index: 1003;
}
.spinner-border {
  width: 40px;
  height: 40px;
  border-width: 2px;
}
