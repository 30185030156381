@import '../_common/c-bottombar';

.c-bottombar__head {
    background-color: $secondary;
    color: $white;
}
.c-bottombar__btnline {
    .btn {
        &:hover,
        &:focus {
            .btn-ico {
                background-color: $primary-dark;
                color: $white;
            }
        }
    }
    .btn-ico {
        border-radius: 50%;
        background-color: $primary;
        color: $white;
        font-size: 1.75rem;
    }
}