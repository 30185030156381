.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    padding: 10px 30px;
    border-radius: 4px;
    &-check {
        &:active + .btn-light,
        &:focus + .btn-light {
            box-shadow: none !important;
        }
    }
}

.btn-sm {
    min-width: 8rem;
    padding: 8px 16px;
}
.btn-ico {
    width: 1em;
    height: 1em;
    margin-left: .5em;
    margin-right: .5em;
    fill: currentColor;
}
.btn-ico-left {
    margin-left: -.25em;
}
.btn-ico-right {
    margin-right: -.25em;
}

.btn-link {
    min-width: auto;
    text-decoration: none;
    border-radius: 0;
    white-space: nowrap;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }
}