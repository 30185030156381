@import '../_common/c-engine';

.c-engines {
    margin-top: 0;
    margin-bottom: 2.5rem;
    background-color: $gray-100;
}
.c-engine {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.5rem;
    border-radius: 4px;
    text-align: inherit;
    &.active {
        border-radius: 6px;
    }
}
.c-engine__title {
    margin: 0;
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
}
.c-engine__body {
    flex: 1;
}
.c-engine__price {
    .c-price {
        font-weight: inherit;
    }
}
.c-engine__descr {
    color: $gray-700;
    font-size: .6875rem;
}
.c-engine__tech {
    display: none;
}
.c-engine__btnline {
    margin-bottom: 0;
}
.c-engine__footer {
    align-items: flex-end;
    justify-content: initial;
    padding: 0;
    border: 0;
}
.c-engine__check {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    padding: 10px 30px;
    border-radius: 50rem;
    background-color: $primary;
    color: $white;
    text-align: center;
    transition: .15s ease-in-out all;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        color: $white;
        border-color: $primary-dark;
        background-color: $primary-dark;
    }
    .active & {
        border-color: $gray-100;
        background-color: $gray-100;
        color: $black;
    }
    input {
        display: none;
    }
    label {
        cursor: inherit;
    }
}