.c-accordion__toggler {
    padding: 0.75rem 0;
    font-weight: $font-weight-bold;
    letter-spacing: inherit;
    &::after {
        margin-left: 0.75rem;
    }
}
.c-accordion__body {
    padding: 0;
}
.c-accordion__count {
    margin-left: 0.5rem;
    letter-spacing: normal;
}
.c-accordion__price {
    order: 1;
    margin-left: auto;
}