.c-aside-container {
    
}

.c-aside {
    position: fixed;
    z-index: 1002;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 5rem;
    padding: 0.5rem 1rem;
    background-color: $black;
    color: $white;
    @include media-breakpoint-down(sm) {
        height: 7rem;
    }
    @include media-breakpoint-up(lg) {
        position: sticky;
        top: 1.5rem;
        flex-direction: column;
        align-items: initial;
        height: auto;
        padding: 1rem;
        border: 1px solid $gray-400;
        background-color: $body-bg;
        color: $body-color;
    }
}
.c-aside__head {
    margin: 0;
    @include media-breakpoint-down(md) {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
    }
}
.c-aside__title {
    display: block;
    margin: 0;
}
.c-aside__price {
    display: block;
    margin: 0;
    @include media-breakpoint-up(lg) {
        margin-bottom: 0.5rem;
    }
}
.c-aside__btnline {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    margin: -0.25rem;
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
    @include media-breakpoint-up(lg) {
        flex-direction: column;
    }
    .btn {
        margin: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        @include media-breakpoint-down(sm) {
            width: 100%;
            &:not(.btn-primary) {
                flex: 1;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                font-size: 0.75rem;
            }
        }
    }
}