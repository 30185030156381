@import '~swiper/swiper';

.card-swiper {
    width: auto;
    padding-bottom: 2rem;
    .swiper-slide {
        width: 376px;
        height: auto;
        margin: 0.75rem;
        @include media-breakpoint-down(sm) {
            width: 80vw;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .swiper-button {
        position: absolute;
        z-index: 1;
        top: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 2rem;
        height: 3.75rem;
        padding: 0;
        border: 0;
        background-color: $black;
        color: $white;
        transform: translateY(-50%);
        transition: .15s ease-in-out all;
        cursor: pointer;
        opacity: .75;
        &:hover {
            opacity: 1;
        }
        &:focus {
            outline: 1px dotted $gray-900;
        }
        svg {
            width: inherit;
            fill: currentColor;
        }
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
    .swiper-button-disabled {
        display: none;
    }
    .swiper-pagination {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        line-height: 0;
    }
    .swiper-pagination-lock {
        display: none;
    }
    .swiper-pagination-bullet {
        position: relative;
        width: 1.375rem;
        height: 1.375rem;
        padding: 0;
        overflow: hidden;
        border: 0;
        background: none;
        text-indent: 100%;
        white-space: nowrap;
        cursor: pointer;
        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: 1px solid $gray-400;
            background-color: $white;
            background-clip: content-box;
            transition-duration: 0s;
            transform: translate(-50%,-50%);
            content:"";
        }
    }
    .swiper-pagination-bullet-active {
        &::before {
            width: 14px;
            height: 14px;
            border-width: 2px;
            border-color: transparent;
            background-color: $primary;
            box-shadow: 0 0 0 1px $primary;
            transition-duration: .3s;
        }
    }
}

.swatch-swiper {
    width: 100%;
    overflow: visible;
    .swiper-slide {
        width: auto;
        &:first-child .swiper-group {
            margin-left: 0;
        }
        &:last-child .swiper-group {
            margin-right: 0;
        }
    }
    .swiper-button {
        position: absolute;
        z-index: 1;
        top: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 5rem;
        padding: 0;
        border: 0;
        background-color: transparent;
        color: $primary;
        cursor: pointer;
        opacity: .75;
        &:hover {
            opacity: 1;
        }
        &:focus {
            outline: 1px dotted $gray-900;
        }
        svg {
            width: 2.25rem;
            fill: currentColor;
        }
    }
    .swiper-button-prev {
        right: 100%;
    }
    .swiper-button-next {
        left: 100%; 
    }
    .swiper-button-disabled {
        display: none;
    }
    .swiper-group {
        display: flex;
        position: relative;
        margin: 0 1rem 2rem;
    }
    .swiper-label {
        position: absolute;
        top: 100%;
        left: 0.5rem;
        right: 0.5rem;
        height: 2rem;
        padding-top: 0.5rem;
        border-top: 1px solid $gray-700;
        font-weight: $font-weight-bold;
        text-align: center;
        white-space: nowrap;
    }
}