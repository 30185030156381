.c-search-form {
    position: relative;
    display: flex;
    align-items: center;
}
.c-search-form__input {
    height: 3rem;
    padding-right: 4.5rem;
}
//.c-search-form__ico {
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    width: 1em;
//    height: 1em;
//    margin: -.45em 0 0 -.5em;
//    fill: currentColor;
//    stroke-width: 0;
//}
.c-search-form__btn,
.c-search-form__clear {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: $form-check-input-border-radius;;
    //.c-btn {
    //    height: 100%;
    //    line-height: normal;
    //}
}
.c-search-form__clear {
    right: 2.5rem;
    width: 2rem;
    opacity: 0.65;
    &:hover,
    &:focus {
        opacity: 1;
    }
}