.nav-tabs {
    justify-content: center;
    .nav-link {
        height: 3rem;
        color: $gray-700;
        border-bottom: 3px solid transparent;
        text-transform: uppercase;
        &.active {
            font-weight: $font-weight-bold;
        }
    }
}