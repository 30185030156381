.modal-content {
    border-radius: 0;
}
.modal-header {
    box-shadow: 0 0 3px rgba(0,0,0,.25);
    .btn-close {
        margin-right: 0;
        border: 1px solid;
        border-radius: 50%;
        font-size: 0.625rem;
        transition: .3s transform;
        &:hover {
            transform: rotate(90deg);
        }
    }
}
.modal-title {
    margin: 0;
    font-size: 1.25rem;
    text-transform: uppercase;
}
.modal-body {
    padding: 1.5rem;
    h6 {
        margin: 1rem 0;
        font-size: inherit;
        text-transform: uppercase;
    }
    ul {
        margin: 0 0 2rem;
        padding-left: 1.25rem;
        list-style: none;
        li::before {
            float: left;
            width: 0.25em;
            height: 0.25em;
            margin-top: 0.85em;
            margin-left: -1em;
            border-radius: 50%;
            background-color: currentColor;
            line-height: 1;
            content: "";
        }
    }
}

.offer-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 320px;
    }
    .spinner-wrap {
        position: relative;
    }
}