@import '../_common/c-aside';

.c-aside {
    @include media-breakpoint-up(lg) {
        border-width: 1rem;
        border-color: $gray-100;
    }
}
.c-aside__title {
    font-size: .75rem;
    font-weight: inherit;
    @include media-breakpoint-up(lg) {
        margin-bottom: 0.5rem;
        font-size: inherit;
    }
}
.c-aside__price {
    font-size: inherit;
    font-weight: $font-weight-bold;
    @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
    }
}