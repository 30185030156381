.c-threesixty {
    padding: 0;
    .c-vehicle__img,
    .v360-viewport {
        padding-bottom: 56.25%;
    }
    .c-threesixty__vehicle {
        background-color: $body-bg;
        color: $body-color;
        &.fullscreen-enabled {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:not(.fullscreen-enabled) {
            .c-vehicle__img,
            .v360-viewport {
                @include media-breakpoint-up(xl) {
                    padding-bottom: 675px;
                }
            }
        }
    }
    .c-vehicle__body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    .c-vehicle__caption {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}
.c-threesixty__nav {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $gray-100;
    .c-threesixty__tabs {
        margin-top: -3rem;
    }
}
.c-threesixty__colors {
    display: flex;
    align-items: center;
    padding: 1.5rem 3rem;
    background-color: $white;
    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
    @include media-breakpoint-up(lg) {
        .swatch-swiper {
            width: 75%;
        }
    }
}
.c-threesixty__descr {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin: 0;
    @include media-breakpoint-down(lg) {
        align-items: center;
        width: auto;
        margin-bottom: 1rem;
    }
}
.c-threesixty--interior {
    .c-threesixty__vehicle {
        background-color: $black;
        color: $white;
    }
    .c-vehicle__caption {
        display: none;
    }
}