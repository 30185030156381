@import '../_common/c-package';

.c-package {
    border: 1px solid $gray-400;
    border-radius: $card-border-radius;
    &.is-active {
        border-color: $primary;
    }
    &:hover {
        box-shadow: 0 3px 15px 0 rgba(0,0,0,.25);
    }
}
.c-package__img {
    background-color: $gray-100;
}
.c-package__code {
    color: $gray-700;
    font-size: 1rem;
    font-weight: inherit;
}
.c-package__title {
    font-weight: $font-weight-bold;
}
.c-package__price {
    font-size: 0.875rem;
    font-weight: inherit;
}
.c-package__btn {
    border-color: $gray-500;
    border-radius: $card-border-radius;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    .c-package.is-active & {
        border-color: transparent;
        background-color: $primary;
        color: $white;
    }
}

.c-pkg__title {
    font-size: .875rem;
    font-weight: inherit;
    .c-pkg.is-active & {
        font-weight: $font-weight-bold;
    }
}