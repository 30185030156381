.c-steps {
    position: relative;
    background-color: $black;
    color: $white;
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(lg) {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.c-steps__toggler {
    position: absolute;
    right: 1rem;
    bottom: 100%;
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin-bottom: 5px;
    border-color: transparent;
    color: $primary;
    font-size: inherit;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    &:hover {
        color: $primary;
    }
    &:focus {
        box-shadow: none;
    }
    .c-ico {
        width: 1em;
        height: 1em;
        transition: .35s ease transform;
        transform: rotate(180deg);
    }
    &.is-active {
        .c-ico {
            transform: rotate(0);
        }
    }
}
.c-steps__collapse {
    flex: 1;
    @include media-breakpoint-up(lg) {
        &:not(.show) {
            display: block;
        }
    }
}
.c-steps__nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}
.c-steps__link {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        width: auto;
        white-space: nowrap;
    }
}
.c-steps__text {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(lg) {
        justify-content: center;
    }
}
.c-steps__count {
    width: 1.35rem;
    font-size: inherit;
    &::before {
        content: '0';
    }
    &::after {
        content: ".";
    }
}
.c-steps__ico {
    width: 1.75rem;
    height: 1em;
    font-size: 1.5rem;
    fill: currentColor;
}