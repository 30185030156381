.c-error {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
}
.c-error__title {
    margin-bottom: 0.75rem;
}
.c-error__descr {
    margin-bottom: 1rem;
}
.c-error__btnline {
    margin-top: 1.25rem;
    margin-bottom: 0;
}