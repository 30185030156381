.c-models-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    .c-model {
        width: 100%;
        max-width: 360px;
        padding: 1rem;
        @include media-breakpoint-down(md) {
            margin-left: auto;
            margin-right: auto;
        }
        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 33.3333%;
        }
        @include media-breakpoint-up(xxl) {
            width: 25%;
        }
    }
}

.c-model {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: 100%;
    margin: 0;
    padding: 0.5rem;
    background-color: $white;
    color: inherit;
    text-decoration: none;
    word-wrap: break-word;
}
.c-model__img {
    position: relative;
    height: 0;
    margin: 0;
    padding-bottom: 66.6667%;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding-bottom: 200px;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.c-model__body {
    flex: 1;
    padding: 1rem;
}
.c-model__title {
    display: block;
    margin: 0;
    font-size: inherit;
}
.c-model__price {
    display: block;
    margin: 1rem 0 0;
    .c-price {
        color: inherit;
    }
    .c-price__title {
        margin-right: .3em;
    }
    .c-price__hint {
        position: static;
        display: inline-block;
        width: 1em;
        margin: 0 0 0 .3em;
        color: inherit;
        font-size: .75rem;
        line-height: inherit;
    }
    .c-price__hint-ico {
        vertical-align: baseline;
        width: 100%;
    }
}
.c-model__descr {
    display: block;
    margin: .25rem 0 0;
    color: $gray-700;
    font-size: .6875rem;
}
.c-model__tech {
    display: block;
    margin: 0;
    padding: .75rem 0;
    border-top: 1px solid $gray-400;
}
.c-model__btnline {
    display: block;
    margin: 0;
    .btn {
        width: 100%;
    }
}
.c-model__footer {
    margin-top: auto;
    padding: 0 1rem 1rem;
}