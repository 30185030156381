.c-badge-list {
    display: flex;
    margin: .5rem 0 0;
}
.c-badge {
    display: inline-block;
    margin-right: .5rem;
    padding: 1px .5rem 3px;
    border: 1px solid;
    border-radius: 2px;
    border-color: $gray-600;
    color: $gray-800;
    font-size: .75rem;
    font-weight: $font-weight-normal;
    line-height: 1.2;
    text-transform: lowercase;
}
.c-badge--hybrid {
    border-color: currentColor;
    color: #0091da;
}
.c-badge--electric {
    border-color: currentColor;
    color: #6fb0a8;
}
.c-badge--plugin {
    border-color: currentColor;
    color: #155868;
}