.c-cart__head {
    margin-bottom: 1rem;
}
.c-cart__title {
    margin: 0;
}
.c-cart__subtitle {
    margin: 0;
}
.c-cart__body {
    .list-group-close {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.25rem;
        border: 0;
        color: $danger;
        line-height: 1;
    }
}
.c-cart__foot {
    margin-top: 1rem;
}
.c-cart__hint {
    margin: 0;
    font-size: .6875rem;
    line-height: $line-height-lg;
}