.c-selection {
    padding: 1rem;
}
.c-selection__title {
    display: block;
    margin: 1rem 0;
    text-align: center;
}
.c-selection__list {
    list-style: none;
    margin: 0.5rem;
    padding: 0.25rem 1rem;
    border: 1px solid $gray-400;
    font-size: .8125rem;
    line-height: 1;
}
.c-selection__item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 1rem 0;
    &::before {
        content: unset !important;
    }
    .c-item__ico {
        flex-shrink: 0;
        fill: currentColor;
        width: 1em;
        height: 1em;
        margin-right: 0.5rem;
        border-radius: 50%;
        font-size: 1rem;
    }
    .c-item__ico--del {
        color: $danger;
    }
    .c-item__ico--add {
        color: $success;
    }
    .c-item__descr {
        display: flex;
        max-width: 80%;
        margin: 0;
    }
    .c-item__title {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .c-item__code {
        margin-left: 0.25rem;
        color: $gray-700;
    }
    .c-item__price {
        display: flex;
        flex: 1;
        font-weight: $font-weight-bold;
        text-align: right;
        white-space: nowrap;
        &::before {
            flex: 1;
            margin: 0 0.25rem;
            border-bottom: 1px dotted $gray-500;
            content: "";
        }
        &:empty {
            display: none;
        }
    }
}
.c-selection__diff {
    display: flex;
    margin: 2rem -1rem;
    .c-diff__item {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        background-color: $gray-100;
    }
    .c-diff__item--primary {
        background-color: $black;
        color: $white;
    }
}
.c-selection__btnline {
    display: flex;
    justify-content: center;
    .btn {
        margin: 0 0.5rem;
    }
}