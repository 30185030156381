@import '../_common/c-selection';

.c-selection__title {
    font-size: 1rem;
    font-weight: $font-weight-normal;
}
.c-selection__item {
    .c-item__code {
        color: $gray-800;
    }
}
.c-selection__diff {
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    .c-diff__item--primary {
        background-color: $secondary;
        color: $white;
    }
}
.c-selection__btnline {
    .btn-secondary {
        border-color: transparent;
        background-color: $secondary;
        color: $white;
        &:hover,
        &:focus,
        &:active {
            background-color: $black;
            color: $white;
        }
    }
}