.c-disclaimer {
    &.c-section {
        margin-top: auto;
        padding-bottom: 6rem;
        @include media-breakpoint-down(sm) {
            padding-bottom: 8rem;
        }
    }
}
.c-disclaimer__content {
    margin: 0;
    color: $gray-700;
    font-size: .6875rem;
    line-height: $line-height-lg;
}