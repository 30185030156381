@font-face {
  font-family: "Toyota";
  src: url('../../fonts/theme-wess/ToyotaType-Book.woff2') format("woff2"), url('../../fonts/theme-wess/ToyotaType-Book.woff') format("woff");
  font-weight: 300;
  font-display: swap;
  ascent-override: 95%;
}
@font-face {
  font-family: "Toyota";
  src: url('../../fonts/theme-wess/ToyotaType-Regular.woff2') format("woff2"), url('../../fonts/theme-wess/ToyotaType-Regular.woff') format("woff");
  font-weight: 400;
  font-display: swap;
  ascent-override: 95%;
}
@font-face {
  font-family: "Toyota";
  src: url('../../fonts/theme-wess/ToyotaType-Semibold.woff2') format("woff2"), url('../../fonts/theme-wess/ToyotaType-Semibold.woff') format("woff");
  font-weight: 700;
  font-display: swap;
  ascent-override: 95%;
}