.c-vehicle {
    position: relative;
}
.c-vehicle__img {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    margin: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
        padding-bottom: 324px;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.c-vehicle__body {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.c-vehicle__caption {
    position: relative;
    z-index: 1;
    align-self: flex-start;
    margin: 0;
    @include media-breakpoint-up(md) {
        max-width: 480px;
    }
}
.c-vehicle__info {
    position: relative;
    z-index: 1;
    max-width: 380px;
    margin: 0 0 0 auto;
    @include media-breakpoint-down(lg) {
        display: none;
    }
    .c-info__list {
        margin: 1rem 0;
        padding-left: 1.25rem;
        list-style: none;
    }
    .c-info__item::before {
        float: left;
        width: 0.25em;
        height: 0.25em;
        margin-top: 0.5em;
        margin-left: -1em;
        border-radius: 50%;
        background-color: currentColor;
        font-size: 1.25rem;
        line-height: 1;
        content: "";
    }
}
.c-vehicle__action {
    position: relative;
    z-index: 1;
    align-self: flex-end;
    width: 100%;
    margin: 0;
    text-align: right;
}