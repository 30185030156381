@import '../_common/c-grade';

.c-grade {
    padding: 0;
    background-color: transparent;
}
.c-grade__title {
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
}
.c-grade__price {
    .c-price {
        font-size: 0.875rem;
        font-weight: inherit;
    }
}