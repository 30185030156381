@import '../_common/c-vehicle';

.c-vehicle__img {
    @include media-breakpoint-up(md) {
        &--left {
            width: 60%;
            margin-right: auto;
        }
        &--right {
            width: 60%;
            margin-left: auto;
        }
    }
}
.c-vehicle__caption {
    @include media-breakpoint-up(md) {
        max-width: 420px;
    }
}
.c-vehicle__info {
    font-size: 0.875rem;
    .c-info__item::before {
        margin-top: 0.55em;
        font-size: 1.125em;
    }
    .c-info__btn {
        .btn {
            width: 100%;
        }
    }
}