@import '../_common/buttons';

.btn {
    border-radius: 50rem;
}

.btn-primary {
    color: $white;
    &:hover,
    &:focus,
    &:active {
        color: $white;
        border-color: $primary-dark;
        background-color: $primary-dark;
    }
}
.btn-secondary {
    border-color: currentColor;
    background-color: transparent;
    color: inherit;
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
        background-color: darken($gray-100, 4%);
        color: $black;
    }
}
.btn-light {
    border-color: $gray-100;
    background-color: $gray-100;
    color: $black;
    &:hover,
    &:focus,
    &:active {
        background-color: darken($gray-100, 4%);
        color: $black;
    }
}