@import '../_common/c-total';

.c-total {
    background-color: $gray-200;
}
.c-total__body {
    padding: 1.5rem;
}
.c-total__title {
    text-transform: uppercase;
}
.c-total__price {
    font-size: inherit;
    font-weight: $font-weight-normal;
}
.c-total__hint {
    color: inherit;
    font-size: 0.8125rem;
}