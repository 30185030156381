.c-package {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all .2s ease-in-out;
}
.c-package__img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    margin: 0;
}
.c-package__body {
    padding: 1rem;
}
.c-package__footer {
    margin-top: auto;
}
.c-package__code {
    margin: 0;
}
.c-package__title {
    margin: 0;
}
.c-package__price {
    margin: 0;
    padding: 0 1rem;
}
.c-package__btn {
    float: right;
    margin: 0 -1px -1px 0;
}

.c-pkg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    border-top: 1px solid $gray-400;
}
.c-pkg__title {
    margin: 0;
}
.c-pkg__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    width: 2em;
    height: 2em;
    padding: 0;
    border-radius: 50%;
    border: 0;
    font-size: 1.072em;
    .c-pkg:not(.is-active) & {
        background-color: transparent;
        color: inherit;
    }
    .btn-ico {
        margin: 0;
    }
}