@mixin background-shadow {
    display: table;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    box-shadow: 0 0 5em $white inset;
}
.c-caption {

}
.c-caption__title {
    display: block;
    margin: 0;
    font-size: 1.875rem;
    @include background-shadow;
}
.c-caption__descr {
    display: block;
    margin: 0.25rem 0 0;
    font-size: 0.875rem;
    @include background-shadow;
}
.c-caption__price {
    display: block;
    margin: 1rem 0 0;
    .c-price__title {
        display: block;
        font-size: 0.875rem;
        @include background-shadow;
    }
    .c-price__value {
        font-size: 1.5rem;
        font-weight: $font-weight-bold;
        @include background-shadow;
    }
}
.c-caption__btn {
    display: block;
    margin: 1rem 0 0;
    @include background-shadow;
}