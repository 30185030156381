@import '../_common/form';

.form-text {
    color: $gray-500;
}
.form-check-input {
    border-color: transparent;
    background-color: $gray-100;
    &:checked {
        background-color: $primary;
        color: $white;
    }
}