@import '../_common/c-steps';

.c-steps {
    background-color: $secondary;
    color: $white;
}
.c-steps__toggler {
    margin-bottom: 8px;
    color: inherit;
}
.c-steps__nav {
    font-size: .875rem;
    @include media-breakpoint-up(lg) {
        justify-content: center;
    }
}
.c-steps__link {
    color: $gray-400;
    font-weight: $font-weight-normal;
    text-align: left;
    text-transform: none;
    &.active {
        color: inherit;
    }
    @include media-breakpoint-up(lg) {
        height: 3.5rem;
    }
}
.c-steps__text {
    @include media-breakpoint-up(lg) {
        margin: 0 1.25rem;
    }
}
.c-steps__count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 0.625rem;
    border-radius: 50%;
    background-color: $gray-900;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    &::before {
        content: unset;
    }
    &::after {
        content: unset;
    }
    .active & {
        background-color: $white;
        color: $secondary;
    }
}
