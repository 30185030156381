.c-total {
    border: 0;
    background-color: $gray-100;
}
.c-total__body {
    padding: 2rem;
}
.c-total__title {
    margin: 0 0 1.5rem;
    padding: 0 0 1rem;
    border-bottom: 1px solid $gray-500;
    font-size: 1.75rem;
    font-weight: $font-weight-normal;
}
.c-total__price {
    margin: 0;
    font-size: 1.75rem;
    font-weight: $font-weight-bold;
}
.c-total__hint {
    margin: 1rem 0 0;
    color: $gray-700;
    font-size: 0.75rem;
}