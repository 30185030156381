.form-group {
    display: block;
    margin: 1rem auto;
    @include media-breakpoint-up(lg) {
        max-width: 60%;
    }
}
.form-label {
    &.required:after {
        margin-left: 0.125rem;
        color: $danger;
        content: '*';
    }
}
.form-control,
.form-select {
    height: 2.5rem;
    font-size: inherit;
}
.form-text {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: $gray-700;
    font-size: 0.75rem;
    text-align: justify;
}
.form-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.form-check {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    padding: 0;
}
.form-check-inline {
    display: inline-flex;
    margin-right: 1rem;
}
.form-check-input {
    -webkit-appearance: none;
            appearance: none;
    background-color: $white;
    margin: 0;
    font: inherit;
    color: currentColor;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    border-radius: $form-check-input-border-radius;
    font-size: 1.25rem;
    transition: .35s ease all;
    transition-property: border-color, background-color, color;
    &[type="radio"] {
        border-radius: 50%;
    }
    &.is-invalid {
        border-color: $danger;
    }
    &:hover {
        background-color: $gray-100;
    }
    &:checked {
        border-color: $primary;
        background-color: $white;
        &::before {
            position: absolute;
            content: "";
        }
        &[type="checkbox"] {
            background-image: none;
            &::before {
                width: .25em;
                height: .5em;
                margin-top: 0;
                border: .075em solid;
                border-top-width: 0 !important;
                border-left-width: 0 !important; 
                transform: rotate(45deg);
                transform-origin: 75% 50%;
            }
        }
        &[type="radio"] {
            background-image: none;
            &::before {
                width: .4em;
                height: .4em;
                border-radius: 50%;
                background-color: currentColor;
            }
        }
    }
    &:disabled {
        opacity: 1;
        background-color: $white;
        color: $gray-700;
        pointer-events: none;
        cursor: not-allowed;
        &:checked {
            background-color: $white;
            color: $gray-700;
        }
    }
    .form-check & {
        float: none;
        margin-left: 0;
        margin-right: 0.5em;
    }
}