@import 'fonts';
@import 'variables';
@import '~bootstrap/scss/bootstrap';

@import 'main.scss';

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    letter-spacing: $body-letter-spacing;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    letter-spacing: $headings-letter-spacing;
}

strong, b {
    font-weight: $font-weight-bold;
}

#root {
    height: 100%;
    > .App {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

#ModeraChatApp {
    .modera-chat {
        font-weight: 400;
        letter-spacing: normal;
        .chat-bubble {
            z-index: 1050;
            bottom: 5.6rem;
        }
        .chat-frame {
            z-index: 1050;
            &.open {
                bottom: 5rem;
            }
            .chat-header {
                display: flex;
                align-items: center;
                .chat-controls {
                    order: 1;
                    margin-left: auto;
                    .control-close {
                        position: relative;
                        width: 28px;
                        height: 28px;
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>');
                        background-size: 20px 20px;
                    }
                }
            }
            .chat-body {
                .chat-post-form {
                    top: 55px;
                    right: 0;
                    bottom: 5px;
                    left: 0;
                    .fieldset {
                        .form-group {
                            max-width: none;
                            margin: 0 0 1rem;
                            .form-control {
                                padding-left: 12px;
                                padding-right: 12px;
                            }
                        }
                    }
                }
            }
            .form-control {
                letter-spacing: normal;
            }
        }
    }
}