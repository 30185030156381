@import '../_common/c-disclaimer';

.c-disclaimer {
    background-color: $gray-100;
    &.c-section {
        padding-top: 3.5rem;
        padding-bottom: 7.5rem;
    }
}
.c-disclaimer__content {
    color: inherit;
    font-size: .8125rem;
    line-height: inherit;
}