@import '../_common/swiper';

.card-swiper {
    .swiper-button {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: $gray-200;
        color: $primary;
        opacity: 1;
        &:hover {
            background-color: $secondary;
            color: $white;
        }
        svg {
            width: 1.5rem;
        }
    }
}
.carpart-swiper {
    .swiper-slide {
        width: calc(50% - 1.5rem);
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}