@import '../_common/modal';

.modal-content {
    border-radius: $card-border-radius;
}
.modal-header {
    box-shadow: none;
    .btn-close {
        border: 0;
        font-size: inherit;
    }
}
.modal-title {
    font-size: 1.5rem;
    font-weight: $font-weight-normal;
    text-transform: none
}
.modal-body {
    font-size: 0.875rem;
    h6 {
        font-size: 1rem;
    }
    ul {
        li::before {
            margin-top: 0.55em;
            font-size: 1.125em;
        }
    }
}