@import '../_common/c-caption';

.c-caption__title {
    font-size: 2.5rem;
    line-height: 1.1;
}
.c-caption__descr {
    font-size: inherit;
}
.c-caption__price {
    .c-price__value {
        font-size: 1.125rem;
    }
}