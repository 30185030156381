@import '../_common/nav';

.nav-tabs {
    .nav-link {
        color: $gray-800;
        border-bottom-width: 1px;
        font-size: 0.875rem;
        text-transform: none;
        &:hover,
        &:focus,
        &:active {
            color: inherit;
        }
        &.active {
            color: $black;
        }
    }
}