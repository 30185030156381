.c-bottombar {
    position: fixed;
    z-index: 1002;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 5rem;
    transition: .3s height ease-in-out;
    &.is-expanded {
        @include media-breakpoint-down(lg) {
        height: 40rem;
        max-height: 70vh;
        &::before {
            position: fixed;
            z-index: -1;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            background-color: rgba(0,0,0,.35);
            content: "";
        }
        }
        @include media-breakpoint-down(sm) {
            height: 100%;
            max-height: none;
        }
    }
}
.c-bottombar__head {
    position: relative;
    z-index: 2;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    height: 5rem;
    padding: 0.5rem 1rem;
    @include media-breakpoint-up(md) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    background-color: $black;
    color: $white;
}
.c-bottombar__info {
    margin: 0 auto 0 0;
    .c-info__title {
        display: block;
        font-size: .75rem;
        font-weight: $font-weight-normal;
    }
    .c-info__price {
        display: block;
        font-size: 1rem;
        font-weight: $font-weight-bold;
    }
}
.c-bottombar__btnline {
    margin: 0 0 0 1rem;
    .btn {
        @include media-breakpoint-down(sm) {
            min-width: auto;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    .btn-ico {
        margin-left: 1rem;
    }
}
.c-bottombar__toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3rem;
    min-height: 3rem;
    margin-left: -1rem;
    color: inherit;
    &:hover,
    &:focus {
        color: $primary;
    }
    svg {
        width: 1em;
        height: 1em;
        color: inherit;
        font-size: inherit;
    }
    .is-expanded & {
        color: $primary;
    }
    .c-toggle__arrow {
        font-size: 1rem;
        .is-expanded & {
            transform: rotate(180deg);
        }
    }
    .c-toggle__cart {
        font-size: 1.875rem;
    }
}
.c-bottombar__body {
    flex: 1;
    padding: 1rem;
    overflow: auto;
    overflow-y: scroll;
    background-color: $body-bg;
    color: $body-color;
}