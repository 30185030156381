@import '../_common/c-section';

.c-section__subtitle {
    font-size: 1.875rem;
    font-weight: $font-weight-bold;
}
.c-section__subheader {
    font-size: 2.5rem;
}
.c-section__description {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: inherit;
}