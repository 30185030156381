.c-swatch {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin: 1rem 0.5rem;
    border-radius: 50%;
    border: 0;
    &.is-active {
        box-shadow: 0 0 0 0.25rem $gray-400;
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: cover;
    }
}
.c-swatch__ico {
    position: absolute;
    right: -0.25rem;
    top: -0.25rem;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
    font-size: 1.25rem;
    visibility: visible;
}