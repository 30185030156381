.c-grade {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: 100%;
    margin: 0;
    padding: 0.5rem;
    background-color: $white;
    word-wrap: break-word;
}
.c-grade__img {
    position: relative;
    height: 0;
    margin: 0;
    padding-bottom: 66.6667%;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding-bottom: 200px;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.c-grade__body {
    flex: 1;
    padding: 1rem;
}
.c-grade__title {
    display: block;
    margin: 0;
}
.c-grade__price {
    display: block;
    margin: 1rem 0 0;
    .c-price {
        color: inherit;
    }
    .c-price__title {
        margin-right: .3em;
    }
    .c-price__hint {
        position: static;
        display: inline-block;
        width: 1em;
        margin: 0 0 0 .3em;
        color: inherit;
        font-size: .75rem;
        line-height: inherit;
    }
    .c-price__hint-ico {
        vertical-align: baseline;
        width: 100%;
    }
}
.c-grade__descr {
    display: block;
    margin: .25rem 0 0;
    color: $gray-700;
    font-size: .6875rem;
}
.c-grade__tech {
    display: block;
    margin: 0;
    padding: .75rem 0;
    border-top: 1px solid $gray-400;
}
.c-grade__btnline {
    display: block;
    margin: 0;
    .btn {
        width: 100%;
    }
}
.c-grade__footer {
    margin-top: auto;
    padding: 0 1rem 1rem;
}