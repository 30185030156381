.c-tires-descr {
    display: block;
    margin: 1rem 0;
    font-size: 0.875rem;
}
.c-tires {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    padding: 0;
    list-style: none;
}
.c-tires__item {
    width: 272px;
    margin: 0 0 1rem;
    padding: 0 1rem;
    text-align: center;
}
.c-tires__img {
    display: block;
    margin: 0 0 1rem;
    overflow: hidden;
    img {
        vertical-align: top;
        max-width: 100%;
    }
}
.c-tires__label {
    color: inherit;
}