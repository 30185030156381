.c-filter-container {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
    .c-filter {
        flex: 1.5;
    }
    .c-search-form {
        flex: 1;
    }
}
.c-filter {
    border-bottom: 1px solid $gray-400;
}
.c-filter__toggler {
    height: 3rem;
    &:focus {
        box-shadow: none;
    }
}
.c-filter__ico {
    margin-right: 0.25rem;
}
.c-filter__count {
    position: absolute !important;
    top: 0.5rem !important;
    left: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    padding: 0;
    border-radius: 50%;
    border: 0.1rem solid;
    background-color: $white !important;
    color: $primary;
}
.c-filter__menu {
    width: 100%;
    padding: 0;
    border: 0;
    @include media-breakpoint-down(md) {
        position: relative !important;
        transform: none !important;
    }
    @include media-breakpoint-up(md) {
        max-width: 33rem;
        padding: 1.75rem;
        box-shadow: $box-shadow;
    }
}
.c-filter__label {
    padding: 0 0 0.75rem;
    color: inherit;
}
.c-filter__item {
    padding: 0.75rem 0;
    border-bottom: 1px solid $gray-400;
    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: $black;
    }
    &.active {
        background-color: transparent;
        color: inherit;
        font-weight: $font-weight-bold;
    }
}
.c-filter__close {
    margin-top: -0.125rem;
    margin-left: 0.25rem;
    color: $primary;
}