.c-category {
    & + & {
        margin-top: 2rem;
    }
}
.c-category__header {
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 1rem;
}
.c-category__title {
    margin: 0;
    font-size: 1.25rem;
}
.c-category__count {
    margin-left: 1rem;
    font-size: 0.875rem;
}
.c-category__more {
    align-items: center;
    margin-top: 2rem;
}

.c-accessory-list {
    flex-direction: row;
    flex-wrap: wrap;
    margin: -0.5rem;
    border-radius: 0;
}
.c-accessory-list__item {
    position: static;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    border: 0;
    background-color: transparent;
    color: inherit;
    @include media-breakpoint-up(sm) {
        width: 50%;
    }
    @include media-breakpoint-up(md) {
        width: 33.3333%;
    }
    @include media-breakpoint-up(xl) {
        width: 20%;
    }
    &.active {
        margin: 0 !important;
        border: 0 !important;
        background-color: transparent;
        color: inherit;
    }
}

.c-accessory {
    flex: 1;
    max-width: 320px;
    overflow: hidden;
    cursor: pointer;
    .active > & {
        border-color: $primary;
    }
    &:hover {
        box-shadow: 0 3px 15px 0 rgba(0,0,0,.25);
    }
}
.c-accessory__img {
    position: relative;
    display: block;
    height: 0;
    margin: 0;
    padding: 0 0 75%;
    cursor: default;
    &.c-accessory__img--zoom {
        cursor: zoom-in;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.c-accessory__body {
    display: flex;
    flex-direction: column;
    background-color: $gray-300;
}
.c-accessory__title {
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    line-height: $input-btn-line-height;
}
.c-accessory__price {
    margin: auto 0 0;
    font-size: 0.875rem;
}
.c-accessory__check {
    position: absolute;
    z-index: 1;
    top: 0.75rem;
    right: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;
    border-radius: $form-check-input-border-radius;
    border: 1px solid $gray-500;
    background-color: $white;
    color: transparent;
    .active & {
        border-color: transparent;
        background-color: $primary;
        color: $white;
    }
}

.c-accessory-list--cart {
    .c-accessory-list__item {
        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }
    .c-accessory {
        flex-direction: row;
        align-items: center;
        max-width: none;
        cursor: auto;
        &:hover {
            box-shadow: none;
        }
    }
    .c-accessory__img {
        width: 90px;
        height: 100%;
        padding: 0;
        cursor: default;
        img {
            border-radius: 0;
        }
    }
    .c-accessory__body {
        height: 100%;
    }
    .c-accessory__check {
        display: none;
    }
}

.c-zoom {
    .modal-header {
        position: relative;
        padding: 0;
        border: 0;
        overflow: hidden;
        .btn-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            margin: 0;
            background-color: $white;
            box-shadow: 0 0 0 3px $white;
        }
    }
}